import React from 'react'

const Footer = () => {
  return (
   <>
   <div className='h-24  flex items-end justify-center pb-4'>
      <h4 className='text-gray-500 text-sm '>Made with &#10084; Sidharth tp </h4>
   </div>
   </>
  )
}

export default Footer
